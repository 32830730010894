import { Link } from 'react-router-dom';
import config from 'config';
import BarChartOutlinedIcon from '@mui/icons-material/BarChartOutlined';
import { Badge, Tooltip } from '@mui/material';

const Dashboard = () => (
    <Badge component={Link} to={config.defaultPath} color={'primary'} sx={{ marginRight: '10px' }}>
        <Tooltip title="Dashboard" placement="top">
            <BarChartOutlinedIcon
                sx={{
                    transition: 'all .2s ease-in-out',
                    color: '#2196f3',
                    '&:hover': { color: '#1565c0', cursor: 'pointer' }
                }}
                fontSize="large"
            />
        </Tooltip>
    </Badge>
);

export default Dashboard;
