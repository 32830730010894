import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from 'layout/MainLayout';
import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import SecureComponent from 'helpers/roleValidation';

// Components //
const Login = Loadable(lazy(() => import('views/login/index')));
const Dashboard = Loadable(lazy(() => import('views/main/Dashboard/index')));
const Commissions = Loadable(lazy(() => import('views/maintainer/Commission/index')));
const PaymentTerm = Loadable(lazy(() => import('views/maintainer/PaymentTerm/index')));
const Documents = Loadable(lazy(() => import('views/process/Documents/index')));
const DocumentDetails = Loadable(lazy(() => import('views/process/Documents/detail')));
const Invoices = Loadable(lazy(() => import('views/maintainer/Invoices/index')));
const Providers = Loadable(lazy(() => import('views/maintainer/Providers/index')));
const Tracking = Loadable(lazy(() => import('views/process/Tracking/index')));
const OcCheck = Loadable(lazy(() => import('views/process/OcCheck/index')));
const NcNdCheck = Loadable(lazy(() => import('views/process/NcNdCheck/index')));
const InvoiceEntry = Loadable(lazy(() => import('views/process/InvoiceEntry/index')));
const ManagementReport = Loadable(lazy(() => import('views/reports/FalabellaTradingManagement/index')));
const HanaReports = Loadable(lazy(() => import('views/reports/HanaReports/index')));
const PendingInvoice = Loadable(lazy(() => import('views/reports/PendingInvoice/index')));
const GeneralReport = Loadable(lazy(() => import('views/reports/GeneralReport/index')));
const DownloadExcelVersion = Loadable(lazy(() => import('views/reports/DownloadExcelVersions/index')));
const DownloadDocumentsBiller = Loadable(lazy(() => import('views/reports/DownloadBillerDocuments/index')));
const DownloadXmlDocuments = Loadable(lazy(() => import('views/reports/DownloadXmlDocuments/index')));
const ProcessedDocumentsReport = Loadable(lazy(() => import('views/reports/ProcessedDocuments/index')));
const AvailablePayableDocumentsReport = Loadable(lazy(() => import('views/reports/AvailablePayableDocuments/index')));
const NotFound = Loadable(lazy(() => import('views/notfound/index')));
const NoAccess = Loadable(lazy(() => import('views/noaccess/index')));
const ArEntry = Loadable(lazy(() => import('views/process/ArEntry/index')));
const CreateAr = Loadable(lazy(() => import('views/process/ArEntry/create')));
const WorkflowRequests = Loadable(lazy(() => import('views/maintainer/Workflow/index')));
const SliSendList = Loadable(lazy(() => import('views/process/SliSend/index')));
const Nif = Loadable(lazy(() => import('views/maintainer/Nif/index')));
const State = Loadable(lazy(() => import('views/maintainer/State/index')));
const City = Loadable(lazy(() => import('views/maintainer/City/index')));
const XmlValidation = Loadable(lazy(() => import('views/process/XmlValidation/index')));
const XmlUpload = Loadable(lazy(() => import('views/process/XmlUpload/index')));
const Hierarchy = Loadable(lazy(() => import('views/maintainer/Hierarchy/index')));
const Roles = Loadable(lazy(() => import('views/maintainer/Roles/index')));

// ==============================|| ROUTING RENDER ||============================== //
const ROLE_GROUPS = {
    ALL: ['Administrador', 'Analista', 'Editor', 'Autorizador', 'Consulta'],
    GENERIC: ['Administrador', 'Analista', 'Editor', 'Autorizador'],
    ADMIN: ['Administrador']
};
export default function ThemeRoutes() {
    let logged = useSelector((state) => state.auth);
    return useRoutes([
        {
            path: '/',
            element: !logged.loggedUser ? <Login /> : <Navigate to={localStorage.getItem('lastPage') || '/biller/main/dashboard'} />
        },
        {
            path: '/biller',
            element: <MainLayout />,
            children: [
                {
                    path: '/biller/main/dashboard',
                    element: SecureComponent(<Dashboard />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/maintainer/commissions',
                    element: SecureComponent(<Commissions />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/documents',
                    element: SecureComponent(<Documents />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/process/documents/:business/:country/:documentNumber',
                    element: logged.loggedUser ? <DocumentDetails /> : <Navigate to="/" />
                },
                {
                    path: '/biller/process/sliSend',
                    element: SecureComponent(<SliSendList />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/xmlValidation',
                    element: SecureComponent(<XmlValidation />, ROLE_GROUPS.GENERIC)
                },
                { path: '/biller/maintainer/invoices', element: SecureComponent(<Invoices />, ROLE_GROUPS.GENERIC) },
                { path: '/biller/maintainer/providers', element: SecureComponent(<Providers />, ROLE_GROUPS.GENERIC) },
                {
                    path: '/biller/maintainer/paymentTerm',
                    element: SecureComponent(<PaymentTerm />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/maintainer/workflowRequests',
                    element: SecureComponent(<WorkflowRequests />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/tracking',
                    element: SecureComponent(<Tracking />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/process/ocCheck',
                    element: SecureComponent(<OcCheck />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/ncNdCheck',
                    element: SecureComponent(<NcNdCheck />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/invoiceEntry',
                    element: SecureComponent(<InvoiceEntry />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/ar/index',
                    element: SecureComponent(<ArEntry />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/ar/create',
                    element: SecureComponent(<CreateAr />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/reports/management',
                    element: SecureComponent(<ManagementReport />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/processedDocs',
                    element: SecureComponent(<ProcessedDocumentsReport />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/pendingInvoice',
                    element: SecureComponent(<PendingInvoice />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/general',
                    element: SecureComponent(<GeneralReport />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/versionesExcel',
                    element: SecureComponent(<DownloadExcelVersion />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/availableDocs',
                    element: SecureComponent(<AvailablePayableDocumentsReport />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/hanaReports',
                    element: SecureComponent(<HanaReports />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/downloadDocumentsBiller',
                    element: SecureComponent(<DownloadDocumentsBiller />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/reports/downloadXmlDocuments',
                    element: SecureComponent(<DownloadXmlDocuments />, ROLE_GROUPS.ALL)
                },
                {
                    path: '/biller/maintainer/nif',
                    element: SecureComponent(<Nif />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/maintainer/state',
                    element: SecureComponent(<State />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/maintainer/city',
                    element: SecureComponent(<City />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/process/xmlUpload',
                    element: SecureComponent(<XmlUpload />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/maintainer/hierarchy',
                    element: SecureComponent(<Hierarchy />, ROLE_GROUPS.GENERIC)
                },
                {
                    path: '/biller/maintainer/roles',
                    element: SecureComponent(<Roles />, ROLE_GROUPS.ADMIN)
                }
            ]
        },
        { path: '/unauthorized', element: <NoAccess /> },
        { path: '*', element: <NotFound /> },
        { path: '/notfound', element: <NotFound /> }
    ]);
}
