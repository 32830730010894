// project imports
import config from 'config';

// action - state management
import * as actionTypes from './actions';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true,
    filters: {
        commerce: null,
        country: null,
        initialDate: null,
        endDate: null,
        docState: null,
        docType: null,
        documentNumber: null,
        provider: null,
        incomeType: null,
        ocNumber: null
    }
};

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        case actionTypes.SAVE_FILTERS:
            let {
                commerce,
                commerceName,
                country,
                countryName,
                initialDate,
                endDate,
                docState,
                docType,
                docNumber,
                provider,
                incomeType,
                ocNumber
            } = action;
            return {
                ...state,
                filters: {
                    commerceId: commerce,
                    commerceName,
                    countryId: country,
                    countryName,
                    initialDate,
                    endDate,
                    docState,
                    docType,
                    docNumber,
                    provider: {
                        dni: provider?.dni,
                        id: provider?.id,
                        name: provider?.name
                    },
                    incomeType,
                    ocNumber
                }
            };
        default:
            return state;
    }
};

export default customizationReducer;
