// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import menuItem from 'menu-items';
import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const { keycloak, initialized } = useKeycloak();
    const logged = useSelector((state) => state.auth);
    menuItem.items[0].children = validateRoles(menuItem.items[0].children, logged.roles);
    const navItems = menuItem.items.map((item) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

const validateRoles = (childrens, roles) => {
    let filteredChildrens = [];
    for (let c of childrens) {
        if (c.hasOwnProperty('allowedRoles')) {
            if (c.allowedRoles.some((r) => roles.includes(r))) {
                filteredChildrens.push(c);
            }
        } else {
            filteredChildrens.push(c);
        }

        if (c.hasOwnProperty('children')) {
            c.children = validateRoles(c.children, roles);
        }
    }

    return filteredChildrens.filter((p) => (p.hasOwnProperty('children') ? p.children.length > 0 : true));
};

export default MenuList;
