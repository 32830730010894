let initialState = {
    loggedUser: null,
    userEmail: null,
    roles: []
};
export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CAMBIAR_USUARIO':
            return {
                ...state,
                loggedUser: action.payload.name,
                userEmail: action.payload.email,
                roles: action.payload.assignedRoles
            };
        default:
            return state;
    }
};
