/**
 * @returns {string} url de dominio
 */
const getDomain = () => {
    const localhost = window.location.hostname.indexOf('localhost') >= 0;
    const qa = window.location.hostname.indexOf('qa') >= 0;
    const prod = window.location.hostname.indexOf('prd') >= 0;

    let bffUrl = '';

    if (localhost) bffUrl = 'http://localhost:3020';
    else if (qa) bffUrl = 'https://qa-bff-bft.falabella.com/bff-bft';
    else bffUrl = 'https://bff-bft.falabella.com/bff-bft';
    return bffUrl;
};

export { getDomain };
