// material-ui
import logo from 'assets/images/logo.svg';
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={logo} alt="Berry" width="100" />
         *
         */

        <img src={logo} alt="Falabella" style={{ marginTop: '10px', marginLeft: 40, height: '50px', transform: 'scale(1.8)' }} />
    );
};

export default Logo;
