import { useKeycloak } from '@react-keycloak/web';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import Loadable from 'ui-component/Loadable';
import { lazy } from 'react';

const NotFound = Loadable(lazy(() => import('views/notfound/index')));

const SecureComponent = (component, allowedRoles) => {
    const { keycloak } = useKeycloak();
    let logged = useSelector((state) => state.auth);
    if (!logged.loggedUser) {
        return <Navigate to="/" />;
    } else {
        if (logged.roles.some((r) => allowedRoles.includes(r))) {
            return component;
        } else {
            return <Navigate to="/notfound" />;
        }
    }
};

export default SecureComponent;
