import Keycloak from 'keycloak-js';
const localhost = window.location.hostname.indexOf('localhost') >= 0;
const qa = window.location.hostname.indexOf('qa') >= 0;
const prod = window.location.hostname.indexOf('prd') >= 0;

let url = '';
let resource = '';
let realm = '';
if (qa || localhost) {
    url = 'https://qa-access-key-corp.falabella.tech/auth/';
    resource = 'Biller';
    realm = 'corp';
} else {
    url = 'https://access-key-corp.falabella.tech/auth/';
    resource = 'Biller';
    realm = 'esti';
}

const keycloak = new Keycloak({
    realm: realm,
    url: url,
    'ssl-required': 'external',
    resource: resource,
    'public-client': true,
    'verify-token-audience': true,
    'use-resource-role-mappings': true,
    'confidential-port': 0,
    clientId: resource,
    initOptions: {
        onLoad: 'login-required'
    }
});

export default keycloak;
