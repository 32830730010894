import { useState, useRef, useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {
    Popper,
    Paper,
    ClickAwayListener,
    Box,
    Stack,
    Typography,
    Divider,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    ButtonBase,
    Snackbar,
    Alert,
    Badge
} from '@mui/material';

import PerfectScrollbar from 'react-perfect-scrollbar';
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';

import config from '../../../../config';
const axios = require('axios').default;

const translations = {
    COMMISSION: 'Comisiones',
    PROVIDER: 'Proveedores',
    'INVOICE-RANGE': 'Rango de folios',
    'PRODUCT-CATEGORY': 'Jerarquia de productos'
};
const NotificationSection = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    // Open es para abrir o cerrar las notificaciones
    const [open, setOpen] = useState(false);
    // Opened indica si las notificaciones se abrieron o no, para cambiar el color de esta // rojo = no abiertas | azul = abiertas
    const [opened, setOpened] = useState(false);
    // Cantidad de notificaciones (se usa para ver cuando actualizar el icono de notificaciones)
    const [loading, setLoading] = useState(false);
    const [notificationList, setNotificationList] = useState([]);
    const [snackbarConfig, setSnackbarConfig] = useState({});
    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const getNotifications = () => {
        let headers = {
            'Access-Control-Allow-Origin': '*',
            'x-country': 'CL',
            'x-commerce': 'Falabella'
        };
        axios
            .get(`${config.bff_bft.baseUrl}/notification`, { headers: headers })
            .then((response) => {
                setNotificationList(response.data);
                setLoading(false);
            })
            .catch((error) => {
                console.error(error);
                setSnackbarConfig({
                    show: true,
                    severity: 'error',
                    message: 'Ha ocurrido un error al intentar obtener las notificaciones'
                });
                setOpen(false);
                setLoading(false);
            });
    };

    useEffect(() => {
        let abortController = new AbortController();

        getNotifications();

        return () => {
            abortController.abort();
        };
    }, []);

    // useEffect(() => {
    //     const interval = setInterval(() => getNotifications(), 5000);
    //     return () => clearInterval(interval);
    // }, []);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
        setOpened(true);
        // setLoading(true);
        getNotifications();
    };
    // Respaldo de handle toggle
    // const handleToggle = () => {
    //     setOpen((prevOpen) => !prevOpen);
    //     setLoading(true);
    //     let headers = {
    //         'Access-Control-Allow-Origin': '*',
    //         'x-country': 'CL',
    //         'x-commerce': 'Falabella'
    //     };
    //     axios
    //         .get(`${config.bff_bft.baseUrl}/notification`, { headers: headers })
    //         .then((response) => {
    //             setNotificationList(response.data);
    //             setLoading(false);
    //         })
    //         .catch((error) => {
    //             setSnackbarConfig({
    //                 show: true,
    //                 severity: 'error',
    //                 message: 'Ha ocurrido un error al intentar obtener las notificaciones'
    //             });
    //             setOpen(false);
    //             setLoading(false);
    //         });
    // };
    const handleRedirect = (url) => {
        setOpen(false);
        navigate(url);
    };
    const closeSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        let config = snackbarConfig;
        setSnackbarConfig({ show: false, severity: config.severity });
    };
    const prevOpen = useRef(open);
    useEffect(() => {
        let abortController = new AbortController();
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }

        prevOpen.current = open;

        return () => {
            abortController.abort();
        };
    }, [open]);

    const notificationListItems = notificationList.map((notification, index) => (
        <>
            <ButtonBase key={index} disableRipple onClick={() => handleRedirect(notification.redirecturl)}>
                <ListItem>
                    <ListItemText sx={{ '&:hover': { cursor: 'pointer' } }}>
                        <b>
                            {translations[notification.notificationtype]} - {notification.registrationDate}
                        </b>
                        <br />
                        {notification.notificationmessage}
                    </ListItemText>
                </ListItem>
            </ButtonBase>
            <Divider />
        </>
    ));

    return (
        <>
            <Snackbar sx={{ zIndex: '10' }} open={snackbarConfig['show']} autoHideDuration={4000} onClose={closeSnackbar}>
                <Alert onClose={closeSnackbar} variant="filled" severity={snackbarConfig['severity']} sx={{ width: '100%' }}>
                    {snackbarConfig['message']}
                </Alert>
            </Snackbar>
            <Badge badgeContent={notificationListItems.length} color={opened ? 'primary' : 'error'} sx={{ marginRight: '10px' }}>
                <CircleNotificationsIcon
                    sx={
                        notificationListItems.length > 0 && opened == false
                            ? {
                                  transition: 'all .2s ease-in-out',
                                  color: '#f44336',
                                  '&:hover': { color: '#B8405E', cursor: 'pointer' }
                              }
                            : {
                                  transition: 'all .2s ease-in-out',
                                  color: '#2196f3',
                                  '&:hover': { color: '#1565c0', cursor: 'pointer' }
                              }
                    }
                    fontSize="large"
                    onClick={handleToggle}
                    ref={anchorRef}
                />
            </Badge>
            <Popper
                placement="bottom-end"
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 14]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard border={false} elevation={16} content={false} boxShadow shadow={theme.shadows[16]}>
                                    <Box sx={{ p: 2 }}>
                                        <Stack>
                                            <Stack direction="row" spacing={0.5} alignItems="center">
                                                <Typography variant="h4">Notificaciones</Typography>
                                            </Stack>
                                        </Stack>
                                    </Box>
                                    <PerfectScrollbar style={{ height: '100%', maxHeight: 'calc(100vh - 300px)', overflowX: 'hidden' }}>
                                        <Box sx={{ p: 2 }}>
                                            <Divider />
                                            <List
                                                component="nav"
                                                sx={{
                                                    width: '100%',
                                                    maxWidth: 500,
                                                    minWidth: 300,
                                                    backgroundColor: theme.palette.background.paper,
                                                    borderRadius: '10px',
                                                    [theme.breakpoints.down('md')]: {
                                                        minWidth: '100%'
                                                    },
                                                    '& .MuiListItemButton-root': {
                                                        mt: 0.5
                                                    }
                                                }}
                                            >
                                                {loading ? (
                                                    <ListItem sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <CircularProgress />
                                                    </ListItem>
                                                ) : notificationList.length > 0 ? (
                                                    notificationListItems
                                                ) : (
                                                    <ListItem>
                                                        <ListItemText>No existen notificaciones para mostrar</ListItemText>
                                                    </ListItem>
                                                )}
                                            </List>
                                        </Box>
                                    </PerfectScrollbar>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
